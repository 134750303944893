/*
Theme Name: REIGN
Theme URI: https://wbcomdesigns.com/downloads/reign-buddypress-theme/
Author: Wbcom Designs
Author URI: https://wbcomdesigns.com/
Description: Premium BuddyPress Theme with Elementor Page Builder support.
Version: 1.0.7
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: reign
Domain Path: /languages/
Tags: one-column, two-columns, right-sidebar, custom-background, custom-menu, featured-images, post-formats, full-width-template, translation-ready, theme-options, threaded-comments, footer-widgets, custom-logo
*/
